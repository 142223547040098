import axios from 'axios';
import 'dotenv/config';

export default class CustomerService {

    static async setExternalClientStatus(externalId, status) {
        return axios.post(process.env.REACT_APP_HB_SERVER + '/mdbCustomers-setClientStatus', {
            params: {
                externalId,
                status
            }
        })
            .then((res) => {
                this.setCustomerStatus(externalId, status);
            }).catch((error) => {
                console.error(error.message);
            });
    }

    static async setCustomerStatus(externalId, st) {
        const status = st ? 'enabled' : 'disabled';
        return axios.post(process.env.REACT_APP_SERVER + '/customers/updateStatusByExternalId', {externalId, status})
            .then((res) => {
                console.log(res.data);
            }).catch((error) => {
                console.error(error.message);
            });
    }

    static async getCustomers(startDate, endDate, isPerson = null, status = null, companyId = null, query = null) {
        if (isPerson === 'person') {
            isPerson = true;
        } else if (isPerson === 'company') {
            isPerson = false;
        }

        //console.log({startDate, endDate, isPerson, status, companyId});
        return axios.get(process.env.REACT_APP_SERVER + 'customers', {
            params: {
                startDate,
                endDate,
                isPerson,
                status,
                companyId,
                query
            }
        })
            .then((res) => {
                console.log('data customers:', res.data);
                return res.data;
            }).catch((error) => {
                console.error(error.message);
            });
    }

    static async findCustomerById(id) {
        return axios.get(process.env.REACT_APP_SERVER + `customers/${id}`)
            .then((res) => {
                console.log(res.data.customer);
                return res.data.customer;
            }).catch((error) => {
                console.error(error.message);
            });
    }

    static async sendEmailChangePassword(email) {

        return axios.get(process.env.REACT_APP_HB_SERVER + `mdbCustomers-sendEmailChangePassword`,{params:{email}}).then((res) => {
            console.log(res.data);
            return res.data;
        }).catch((error) => {
            console.error(error.message);
        })
    }

    /*
    static async generateCustomers(id) {
        axios.post(process.env.REACT_APP_HB_SERVER + 'mdbCustomers-scriptCustomerMigration', {
            limit: 200,
            id: id
        })
            .then((res) => {
                console.log(res.data.last);
                if (res.data.completed == true){
                    console.log('completado!!');
                    this.generateCustomers(res.data.last);
                }else {
                    console.log('no completado');
                }
            }).catch((error) => {
            console.error(error.message);
        });
    }

     */


}
