import axios from 'axios';
import 'dotenv/config';

export default class OrderService {

    static async getCustomerOrders(customerId, year) {
        return axios.get(process.env.REACT_APP_SERVER + 'documents/customer',{params:{customerId, year}} )
            .then((res) =>{
                console.log(res.data);
                return res.data.result;
            }).catch((error) => {
                console.error(error.message);
            });
    }

    static async getSummaryCharValues(customerId, year) {
        return axios.get(process.env.REACT_APP_SERVER + 'documents/metricsByYear', {params:{customerId, year}} )
            .then((res) =>{
                console.log(res.data);
                return res.data.result;
            }).catch((error) => {
                console.error(error.message);
            });
    }

    // Importa las pedidos como documentos en (orders) no formateado aún
    static async generateDocuments(id) {
        axios.post(process.env.REACT_APP_HB_SERVER + 'mdbOrders-scriptOrderMigration', {
            limit: 200,
            id: id
        })
            .then((res) => {
                console.log(res.data.last);
                if (res.data.completed == true) {
                    console.log('completado!!');
                    this.generateDocuments(res.data.last);
                } else {
                    console.log('no completado');
                }
            }).catch((error) => {
            console.error(error.message);
        });
    }
}
