import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter} from 'react-router-dom'
import AppWrapper from './AppWrapper';

import i18n from './i18n';
import {I18nextProvider} from "react-i18next";

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <HashRouter>
            <AppWrapper/>
        </HashRouter>
    </I18nextProvider>,
document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
