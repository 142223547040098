import React, {useRef, useState} from 'react';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Password} from 'primereact/password';
import {useFormik} from "formik";
import {classNames} from 'primereact/utils';
import {sendPasswordResetEmail, signInWithEmailAndPassword} from "firebase/auth";
import {auth} from "../firebase-config";
import {useHistory} from "react-router-dom";
import {Toast} from 'primereact/toast';

export const Login = () => {
    const history = useHistory();
    const [showMessage, setShowMessage] = useState(false);
    const [formData, setFormData] = useState({});
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const toast = useRef(null);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validate: (data) => {
            let errors = {};
            if (!data.email) {
                errors.email = 'El usuario es requerido';
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
                errors.email = 'Dirección de email inválida';
            }
            if (!data.password) {
                errors.password = 'Contraseña es requerida';
            }

            return errors;
        },
        onSubmit: async (data) => {
            setLoading(true);
            await login(data);
        },
    });

    const formikRecover = useFormik({
        initialValues: {
            email: '',
        },
        validate: (data) => {
            let errors = {};
            if (!data.email) {
                errors.email = 'El usuario es requerido';
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
                errors.email = 'Dirección de email inválida';
            }

            return errors;
        },
        onSubmit: async (data) => {
            setLoading(true);
            await recoverPassword(data);
        },
    });

    const login = async (data) => {
        console.log(data.email);
        try {
            const user = await signInWithEmailAndPassword(auth, data.email, data.password);
            history.push('/');
        } catch (error) {
            setLoading(false);
            processError(error.message, error.code);
        }
    }

    const recoverPassword = async (data) => {
        try {
            await sendPasswordResetEmail(auth, data.email);
            toast.current.show({
                severity: 'success',
                summary: '¡Correcto!',
                detail: 'Hemos enviado un email con instrucciones para restablecer tu contraseña',
                life: 5000
            });
            setLoading(false);
            setShowForgotPassword(false);
        } catch (error) {
            setLoading(false);
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Se ha producido un error',
                life: 3000
            });
        }
    }

    const processError = (message, code) => {
        console.error(message);
        switch (code) {
            case "auth/wrong-password":
                toast.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Usuario o contraseña incorrectas',
                    life: 3000
                });
                break;
            case "auth/user-not-found":
                toast.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Usuario o contraseña incorrectos',
                    life: 3000
                });
                break;
        }
    }

    const toggleLogin = () => {
        formik.resetForm();
        formikRecover.resetForm();
        setShowForgotPassword(!showForgotPassword);
    }

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const isFormRecoverFieldValid = (name) => !!(formikRecover.touched[name] && formikRecover.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) &&
            <p className="mb-1 p-0"><small className="p-error">{formik.errors[name]}</small></p>;
    };
    const getFormRecoverErrorMessage = (name) => {
        return isFormRecoverFieldValid(name) &&
            <p className="mb-1 p-0"><small className="p-error">{formikRecover.errors[name]}</small></p>;
    };

    return (
        <div className="login-body">
            <Toast ref={toast} position="top-center"/>
            <div className="login-wrapper">
                <div className="login-panel">
                    <a href="https://www.hellovan.es" className="logo p-link">
                        <img src="assets/layout/images/logo-hellovan.png" className="mb-2" alt="freya-layout"/>
                    </a>
                    <p className="greeting mb-0 pb-0">Bienvenido</p>
                    <p className="message mb-3 mt-0 mb-5 pt-0">a tu software de logística</p>

                    {showForgotPassword
                        ? <form onSubmit={formikRecover.handleSubmit}>
                            <span className="p-input-icon-left">
                                <i className="pi pi-user"/>
                                <InputText id="email" placeholder="Email" name="email"
                                           value={formikRecover.values.email} onChange={formikRecover.handleChange}
                                           className={classNames({'p-invalid': isFormFieldValid('email')})}/>
                            </span>
                            {getFormRecoverErrorMessage('email')}
                            <Button loading={loading} iconPos="right" label="ENVIAR" type="submit" className="p-button-success"/>
                            <button onClick={toggleLogin} className="p-link forget-password mt-2">Volver</button>
                        </form>
                        : <form onSubmit={formik.handleSubmit}>
                            <span className="p-input-icon-left">
                                <i className="pi pi-user"/>
                                <InputText id="email" placeholder="Email" name="email" value={formik.values.email}
                                           onChange={formik.handleChange}
                                           className={classNames({'p-invalid': isFormFieldValid('email')})}/>
                            </span>
                            {getFormErrorMessage('email')}
                            <span className="p-input-icon-left">
                                <i className="pi pi-lock"/>
                                <Password id="password" name="password" value={formik.values.password}
                                          onChange={formik.handleChange} feedback={false} toggleMask
                                          placeholder="Contraseña"
                                          className={classNames({'p-invalid': isFormFieldValid('password')})}/>
                            </span>
                            {getFormErrorMessage('password')}
                            <Button loading={loading} iconPos="right" label="INICIAR SESION" type="submit"
                                    className="p-button-success"/>
                            <button onClick={toggleLogin} className="p-link forget-password mt-2">Recuperar contraseña
                            </button>
                            {/*<p>Don’t you have an account, <button className="p-link">sign up</button></p>*/}
                        </form>
                    }
                </div>
                <div className="login-footer">
                    <h4>HelloVan</h4>
                    <h6>Copyright Ⓒ 2022 HelloVan Solutions</h6>
                </div>
            </div>
        </div>
    )
}
