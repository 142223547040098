import React, {useEffect, useRef, useState} from 'react';
import CustomerService from "../../service/CustomerService";
import CompanyService from "../../service/CompanyService";
import {Button} from 'primereact/button';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Tag} from "primereact/tag";
import {useTranslation} from "react-i18next";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import moment from "moment";
import {SelectButton} from "primereact/selectbutton";
import {useHistory} from "react-router-dom";
import {Menu} from "primereact/menu";

const CustomerList = () => {
    const {t} = useTranslation(["common"]);
    const history = useHistory();
    const [filtering, setFiltering] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState(null);
    const [selectedFilterDate, setSelectedFilterDate] = useState('now');
    const [startFilterDate, setStartFilterDate] = useState(moment().format('YYYY-MM-DD'));
    const [endFilterDate, setEndFilterDate] = useState(moment().add(1, "day").format('YYYY-MM-DD'));
    const [selectedFilterStatus, setSelectedFilterStatus] = useState(null);
    const [filterIsPerson, setFilterIsPerson] = useState(null);
    const [selectedFilterText, setSelectedFilterText] = useState('');
    const [selectedFilterCompany, setSelectedFilterCompany] = useState(null);
    const [filterCompany, setFilterCompany] = useState(null);
    const [loading, setLoading] = useState(true);
    const [customers, setCustomers] = useState(null);
    const [companies, setCompanies] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [selectedCustomers, setSelectedCustomers] = useState(null);

    const menu = useRef(null);
    const toast = useRef(null);
    const dt = useRef(null);

    const itemsExportMenu = [
        {
            label: t('common:exportCSV'),
            icon: 'pi pi-refresh',
            command: () => {
                exportCSV(selectedCustomers != null)
            }
        },
        {
            label: t('common:exportPDF'),
            icon: 'pi pi-times',
            command: () => {
                exportPdf(selectedCustomers != null);
            }
        },
        {
            label: t('common:exportXLS'),
            icon: 'pi pi-times',
            command: () => {
                exportExcel(selectedCustomers != null)
            }
        }
    ];

    useEffect(() => {
        (async () => {
            await getCompanies();
            await getCustomers(startFilterDate, endFilterDate, filterIsPerson, selectedFilterStatus, filterCompany);
        })();

    }, [])

    useEffect(() => {
        if (selectedFilterText !== '') setFiltering(false);
    }, [selectedFilterText])

    const exportColumns = [
        {title: t('common:status'), dataKey: 'status'},
        {title: t('common:name'), dataKey: 'displayName'},
        {title: t('common:email'), dataKey: 'email'},
        {title: t('common:mobile'), dataKey: 'mobileNumber'},
        {title: t('common:prefix'), dataKey: 'mobilePrefix'},
        {title: t('common:postalDeliveryCodes'), dataKey: 'postalDeliveryCodes'},
    ];


    const getCustomers = async (startDate, endDate, isPerson = null, status = null, companyId = null, query = null) => {
        setLoading(true);
        let data = await CustomerService.getCustomers(startDate, endDate, isPerson, status, companyId, query);

        const newList = data.result.map(item => ({
            _id: item._id,
            code: item.code,
            companyId: item.company._id,
            companyName: item.company.name,
            displayName: item.displayName,
            email: item.email,
            dataProviderId: (item.data !== undefined && item.data !== null) ? item.data.providerId : '--',
            mobileCountryCode: item.mobile?.countryCode ?? '',
            mobilePrefixNumber: `${item.mobile?.prefix ?? ''} ${item.mobile?.number ?? ''}`,
            mobilePrefix: item.mobile?.prefix ?? '',
            mobileNumber: item.mobile?.number ?? '',
            status: item.status,
            postalDeliveryCodes: (item.postalDeliveryCodes ?? []).join(' | '),
            createdAt: item.createdAt,
        }))

        setCustomers(newList);
        setLoading(false);
    }

    const getCompanies = async () => {
        let data = await CompanyService.getSmallListCompanies();
        let empty = {name: t('common:allCompanies'), _id: null};
        let result = [empty, ...data.companies];
        console.log(result);
        setCompanies(result);
    }

    const searchCustomers = async () => {
        setFiltering(true);
        await getCustomers(null, null, null, null, null, selectedFilterText);
    }

    const exportItems = [
        {
            label: 'Export  PDF',
            icon: 'pi pi-pdf',
            command: () => {

            }
        },
        {
            label: 'Export CSV',
            icon: 'pi pi-csv',
            command: () => {

            }
        },
    ];

    const filterStatusOptions = [
        {name: t('common:allStatus'), value: null},
        {name: t('common:enabled'), value: 'enabled'},
        {name: t('common:disabled'), value: 'disabled'},
    ];

    const filterDateOptions = [
        {name: t('common:dateOptions.now'), value: 'now'},
        {name: t('common:dateOptions.yesterday'), value: 'yesterday'},
        {name: t('common:dateOptions.last7days'), value: 'last7days'},
        {name: t('common:dateOptions.currentMonth'), value: 'currentMonth'},
        {name: t('common:dateOptions.lastMonth'), value: 'lastMonth'},
        {name: t('common:dateOptions.last12months'), value: 'last12months'},
        {name: t('common:dateOptions.currentYear'), value: 'currentYear'},
        {name: t('common:dateOptions.lastYear'), value: 'lastYear'},
    ];

    const itemStatusTag = (rowData) => {
        let status = 'danger';
        if (rowData.status === 'enabled') {
            status = 'success';
        } else if (rowData.status === 'disabled') {
            status = 'danger';
        }
        return (<Tag className="mr-2" severity={status} value={t(rowData.status)}/>);
    }
    const itemEmail = (rowData) => {
        return (
            <div className="flex flex-row align-items-center">
                {(rowData.emailVerified === true)
                    ? <i className="pi pi-check mr-2"/>
                    : <i className=" pi pi-exclamation-triangle mr-2"/>
                }
                <span>{rowData.email}</span>
            </div>
        );
    }

    const itemPostalDeliveryCodes = (rowData) => {
        if (!rowData.postalDeliveryCodes) {
            return (
                <span></span>
                )
        }
        return (
            <div className="flex flex-row align-items-center">
                <span>{rowData.postalDeliveryCodes ?? ''}</span>
            </div>
        );
    }

    const itemCreated = (rowData) => {
        return (
            <div className="flex flex-row align-items-center">
                <span>{rowData.createdAt}</span>
            </div>
        );
    }

    const itemPhone = (rowData) => {
        return (
            rowData.mobilePrefix != null && rowData.mobileNumber != null
                ? <span>{rowData.mobilePrefix} {rowData.mobileNumber}</span>
                : <span></span>

        );
    }

    const itemProvider = (rowData) => {
        return (
            rowData.dataProviderId != null
                ? <span>{t(`common:providerId.${rowData.dataProviderId}`)}</span>
                : <span></span>

        );
    }

    const onFilterDateChange = async (e) => {

        let start;
        let end;
        switch (e.value) {
            case 'now':
                start = moment().format('YYYY-MM-DD');
                end = moment().add(1, "day").format('YYYY-MM-DD');
                break;
            case 'yesterday':
                start = moment().subtract(1, "day").format('YYYY-MM-DD');
                end = moment().format('YYYY-MM-DD');
                break;
            case 'last7days':
                start = moment().subtract(7, "day").set("hour", 0).set("minute", 0).set("second", 0).format('YYYY-MM-DD');
                end = moment().add(1, "day").format('YYYY-MM-DD');
                break;
            case 'currentMonth':
                start = moment().startOf('month').set("hour", 0).set("minute", 0).set("second", 0).format('YYYY-MM-DD');
                end = moment().endOf('month').set("hour", 23).set("minute", 59).set("second", 59).format('YYYY-MM-DD');
                break;
            case 'lastMonth':
                start = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
                end = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
                break;
            case 'last12months':
                start = moment().subtract(12, 'month').format('YYYY-MM-DD');
                end = moment().format('YYYY-MM-DD');
                break;
            case 'currentYear':
                start = moment().startOf('year').format('YYYY-MM-DD');
                end = moment().endOf('year').format('YYYY-MM-DD');
                break;
            case 'lastYear':
                start = moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD');
                end = moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD');
                break;
            default:
                start = moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD');
                end = moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD');
                break;
        }

        setSelectedFilterDate(e.value);
        setStartFilterDate(start);
        setEndFilterDate(end);
        await getCustomers(start, end, filterIsPerson, selectedFilterStatus, filterCompany, null);
    }

    const selectedFilterOptionTemplate = (option, props) => {
        let msgDate = '';
        switch (option.value) {
            case 'now':
                msgDate = `${moment().format('DD/MM/YYYY')} - ${moment().format('DD/MM/YYYY')}`;
                break;
            case 'yesterday':
                msgDate = `${moment().subtract(1, "day").format('DD/MM/YYYY')} - ${moment().subtract(1, "day").format('DD/MM/YYYY')}`;
                break;
            case 'last7days':
                msgDate = `${moment().subtract(7, "day").format('DD/MM/YYYY')} - ${moment().format('DD/MM/YYYY')}`;
                break;
            case 'currentMonth':
                msgDate = `${moment().startOf('month').format('DD/MM/YYYY')} - ${moment().endOf('month').format('DD/MM/YYYY')}`;
                break;
            case 'lastMonth':
                msgDate = `${moment().subtract(1, 'month').startOf('month').format('DD/MM/YYYY')} - ${moment().subtract(1, 'month').endOf('month').format('DD/MM/YYYY')}`;
                break;
            case 'last12months':
                msgDate = `${moment().subtract(12, 'month').format('DD/MM/YYYY')} - ${moment().format('DD/MM/YYYY')}`;
                break;
            case 'currentYear':
                msgDate = `${moment().startOf('year').format('DD/MM/YYYY')} - ${moment().endOf('year').format('DD/MM/YYYY')}`;
                break;
            case 'lastYear':
                msgDate = `${moment().subtract(1, 'year').startOf('year').format('DD/MM/YYYY')} - ${moment().subtract(1, 'year').endOf('year').format('DD/MM/YYYY')}`;
                break;
            case 'range':

                break;
        }
        if (option) {
            return (
                <div>{msgDate}</div>
            );
        }

        return (
            <span>
            {props.placeholder}
        </span>
        );
    }

    const resetSearch = async () => {
        setSelectedFilterText('');
        setFiltering(false);
        setShowSearch(false);
        await getCustomers(startFilterDate, endFilterDate, filterIsPerson, selectedFilterStatus, filterCompany);
    }

    const isPersonOptions = [
        {icon: 'pi pi-users', value: 'person'},
        {icon: 'pi pi-building', value: 'company'},
    ];

    const isPersonFilterTemplate = (option) => {
        return <i className={option.icon}/>;
    }

    const onChangeSelectedPerson = async (e) => {
        console.log(e.value);
        setFilterIsPerson(e.value);
        await getCustomers(startFilterDate, endFilterDate, e.value, selectedFilterStatus, filterCompany);
    }

    const onChangeFilterStatus = async (e) => {
        console.log(e.value);
        setSelectedFilterStatus(e.value);
        await getCustomers(startFilterDate, endFilterDate, filterIsPerson, e.value, filterCompany);
    }

    const onChangeFilterCompany = async (e) => {
        console.log(e.value);
        setSelectedFilterCompany(e.value);
        setFilterCompany(e.value._id)
        await getCustomers(startFilterDate, endFilterDate, filterIsPerson, selectedFilterStatus, e.value._id);
    }

    const showCustomerDetail = (rowData) => {
        history.push(`customer-details/${rowData._id}`);

    }

    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({selectionOnly});
    }

    const exportPdf = (selectionOnly) => {
        const data = selectionOnly === true ? selectedCustomers : customers;
        import('jspdf').then(jsPDF => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);
                doc.autoTable(exportColumns, data);
                doc.save('customers.pdf');
            })
        })
    }

    const exportExcel = (selectionOnly) => {
        const data = selectionOnly === true ? selectedCustomers : customers;
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(data);
            const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
            const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
            saveAsExcelFile(excelBuffer, 'customers');
        });
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between align-items-center flex-wrap">
                <div className="flex justify-content-between align-items-center mb-2">
                    <SelectButton value={filterIsPerson}
                                  tooltip="Persona o Empresa" tooltipOptions={{position: 'top'}}
                                  options={isPersonOptions} onChange={onChangeSelectedPerson}
                                  itemTemplate={isPersonFilterTemplate}/>
                    <Dropdown disabled={filtering} value={selectedFilterStatus}
                              className="ml-2"
                              options={filterStatusOptions}
                              onChange={onChangeFilterStatus}
                              optionLabel="name" placeholder={t('common:allStatus')} scrollHeight="200"/>
                    <Dropdown disabled={filtering} value={selectedFilterCompany}
                              className="ml-2"
                              options={companies}
                              onChange={onChangeFilterCompany}
                              optionLabel="name" placeholder={t('common:allCompanies')} scrollHeight="200"/>
                </div>
                <div className="flex justify-content-between align-items-center flex-wrap mb-2">
                    <span className="p-input-icon-left">
                        <i className="pi pi-search"/>
                        <InputText disabled={filtering} type="search"
                                   onInput={(e) => setGlobalFilterValue(e.target.value)}
                                   placeholder={t('common:keywordSearch')}/>
                    </span>
                    <Dropdown disabled={filtering} value={selectedFilterDate}
                              valueTemplate={selectedFilterOptionTemplate} className="ml-2"
                              options={filterDateOptions} onChange={onFilterDateChange}
                              optionLabel="name" placeholder="Select a date" scrollHeight="200"/>
                    <Menu model={itemsExportMenu} popup ref={menu} id="popup_menu"/>
                    <Button disabled={!selectedCustomers} icon="pi pi-cloud-download"
                            className="p-button-outlined p-button-secondary ml-2"
                            onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup/>
                </div>

            </div>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-arrow-right " className="p-button-sm p-button-rounded p-button-success mr-2"
                        onClick={() => showCustomerDetail(rowData)}/>
            </React.Fragment>
        );
    }

    return (
        <div className="grid-nogutter">
            <div className="col-12">
                <div className="flex justify-content-between align-items-center mb-5 flex-wrap">
                    <h2 className="m-0 sm:mb-2">{t('common:general.customers')}</h2>
                    <div className="flex justify-content-end align-items-center flex-wrap">
                        <div className="p-inputgroup">
                            <InputText value={selectedFilterText}
                                       onChange={(e) => setSelectedFilterText(e.target.value)}
                                       placeholder={t('common:searchCustomer')}/>
                            {filtering === false
                                ? <Button disabled={selectedFilterText.length == 0} onClick={searchCustomers}
                                          icon="pi pi-search"
                                          className="p-button-success"/>
                                : <Button onClick={resetSearch} icon="pi pi-times"
                                          className="p-button-danger"/>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <DataTable value={customers} paginator rows={25} totalRecords={totalRecords}
                           ref={dt}
                           responsiveLayout="scroll"
                           emptyMessage={t('common:customer-notfound')}
                           rowsPerPageOptions={[10, 25, 50]}
                           header={renderHeader}
                           globalFilter={globalFilterValue}
                           globalFilterFields={['name', 'surname','dataProviderId', 'companyName','displayName', 'email', 'mobileNumber', 'code']}
                           dataKey="_id" loading={loading}
                           selectionMode="checkbox" selection={selectedCustomers}
                           onSelectionChange={e => setSelectedCustomers(e.value)}
                >
                    <Column selectionMode="multiple" style={{minWidth: '3rem'}} exportable={false}/>
                    <Column field="status" style={{minWidth: '4rem'}} body={itemStatusTag} header={t('common:status')}
                            sortable/>
                    <Column field="code" header={t('common:code')} sortable style={{minWidth: '10rem'}}/>
                    <Column field="displayName" header={t('common:name')} sortable style={{minWidth: '12rem'}}/>
                    <Column field="companyName" header={t('common:company')} sortable style={{minWidth: '12rem'}}/>
                    <Column field="dataProviderId" header={t('common:provider')} body={itemProvider} sortable style={{minWidth: '12rem'}}/>
                    <Column field="mobilePrefixNumber" header={t('common:phone')} sortable body={itemPhone}
                            style={{minWidth: '12rem'}}/>
                    <Column field="email" header={t('common:email')} style={{minWidth: '12rem'}} body={itemEmail}/>
                    <Column field="postalDeliveryCodes" header={t('common:postalDeliveryCodes')} style={{minWidth: '12rem'}} body={itemPostalDeliveryCodes}/>
                    <Column field="createdAt" header={t('common:createdAt')} sortable style={{minWidth: '16rem'}} body={itemCreated}/>
                    <Column body={actionBodyTemplate} exportable={false} style={{minWidth: '4rem'}}/>
                </DataTable>
            </div>
        </div>
    );
};

export default CustomerList;
