import {useTranslation} from "react-i18next";
import {useEffect, useRef, useState} from "react";
import CustomerService from "../../service/CustomerService";
import {BlockUI} from "primereact/blockui";
import {Tag} from "primereact/tag";
import {TabPanel, TabView} from "primereact/tabview";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import OrderService from "../../service/OrderService";
import {Dropdown} from "primereact/dropdown";
import {Chart} from "primereact/chart";
import {Menu} from "primereact/menu";
import {confirmDialog} from "primereact/confirmdialog";
import {InputSwitch} from "primereact/inputswitch";
import moment from "moment";
import { useHistory } from 'react-router-dom';
  
  

const CustomerDetails = (props) => {
    const history = useHistory();
    const {t} = useTranslation(["common"]);
    const [customer, setCustomer] = useState(null);
    const [loading, setLoading] = useState(true);
    const [orders, setOrders] = useState([]);
    const [selectedYearFilter, setSelectedYearFilter] = useState('2023');
    const [chartData, setChartData] = useState(null);
    const [totalChartAmount, setTotalChartAmount] = useState(0);
    const [activeCustomer, setActiveCustomer] = useState(true);
    const menu = useRef(null);
    const toast = useRef(null);

    const yearOptions = [
        {name: '2021', value: '2021'},
        {name: '2022', value: '2022'},
        {name: '2023', value: '2023'},
        {name: '2024', value: '2024'},
    ];

    const charSummaryOptions = [
        {name: '2021', value: '2021'},
        {name: '2022', value: '2022'},
        {name: '2023', value: '2023'},
        {name: '2024', value: '2024'},
    ];

    const months = [
        t('common:months.1'),
        t('common:months.2'),
        t('common:months.3'),
        t('common:months.4'),
        t('common:months.5'),
        t('common:months.6'),
        t('common:months.7'),
        t('common:months.8'),
        t('common:months.9'),
        t('common:months.10'),
        t('common:months.11'),
        t('common:months.12'),
    ]

    const itemsMenu = [
        {
            label: t('common:changePassword'),
            icon: 'pi pi-refresh',
            command: () => {
                confirmSendChangePassword().then();
            }
        },
        /*
        {
            label: t('common:deleteCustomer'),
            icon: 'pi pi-times',
            command: () => {
                confirmDeleteCustomer();
            }
        }

         */
    ];


    let basicOptions = {
        plugins: {
            legend: {
                display: false,
            }
        },
        maintainAspectRatio: false,
        hover: {
            mode: 'index'
        },
        scales: {
            x: {
                display: true,
                grid: {
                    color: 'transparent',
                },
                ticks: {
                    color: '#BFC2C6'
                }
            },
            y: {
                display: true,
                grid: {
                    color: 'rgba(191, 194, 198, .45)',
                    borderDash: [5, 10],
                },
                ticks: {
                    color: '#BFC2C6',
                    min: 0,
                    stepSize: 5,
                }
            }
        }
    };

    useEffect(() => {
        (async () => {

            await getCustomerProfile(props.id);
            getOrders(props.id, selectedYearFilter).then((response) => {
                setOrders(response);
            });
            await getChartSummaryAmounts(props.id, selectedYearFilter);

        })();
    }, [])

    const setStatusCustomer = (data) => {
        console.log('status;', data?.status);
        setActiveCustomer(data?.status === 'enabled');
    }
    const onChartFilterYearChange = async (e) => {
        setSelectedYearFilter(e.value);
        const values = await getChartSummaryAmounts(props.id, e.value);
    }

    const getChartSummaryAmounts = async (id, year) => {
        const result = await OrderService.getSummaryCharValues(id, year);
        const dataValues = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        result.forEach((item) => {
            // dataValues[item._id.account_balances_month - 1] = Number(item.month_value.$numberDecimal);
            dataValues[item._id.account_balances_month - 1] = Number(item.month_value);
        });
        setTotalChartAmount(dataValues.reduce((a, b) => a + b, 0));
        const borderColor = getComputedStyle(document.body).getPropertyValue('--primary-color') || '#2c84d8';
        const backgroundColor = getComputedStyle(document.body).getPropertyValue('--primary-lighter-color') || '#2c84d8';
        setChartData({
            labels: months,
            datasets: [
                {
                    label: t('common:sales'),
                    data: dataValues,
                    borderColor: [borderColor],
                    borderWidth: 4,
                    fill: true,
                    backgroundColor: [backgroundColor],
                    tension: .4,
                }
            ]
        })
        return result;
    }
    const getCustomerProfile = async (id) => {
        const data = await CustomerService.findCustomerById(id);
        setCustomer(data);
        setStatusCustomer(data);
        setLoading(false);
    }

    const getOrders = async (id, year) => {
        return OrderService.getCustomerOrders(id, year);
    }

    const showCustomerDetail = (rowData) => {
        console.log(rowData);
    }

    const onFilterYearChange = (e) => {
        setSelectedYearFilter(e.value);
        getOrders(props.id, e.value).then((response) => {
            setOrders(response);
        });
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <Button icon="pi pi-arrow-right " className="p-button-sm p-button-rounded p-button-success mr-2"
                        onClick={() => showCustomerDetail(rowData)}/>
            </>
        );
    }

    const confirmSendChangePassword = async () => {
        confirmDialog({
            message: t('common:confirmChangePassword.message'),
            header: t('common:confirmChangePassword.title'),
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: t('common:send'),
            rejectLabel: t('common:cancel'),
            accept: () => {
                CustomerService.sendEmailChangePassword(customer.email).then((response) =>{
                    console.log(response);
                });
            },
            reject: () => {
            }
        });
    }

    const setUserActive = (e) => {
        confirmDialog({
            message: t('common:confirmChangeStatus.message'),
            header: e.value === false ? t('common:confirmChangeStatus.titleDisable') : t('common:confirmChangeStatus.titleEnable'),
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: e.value === false ? 'p-button-danger' : 'p-button-success',
            acceptLabel: e.value === false ? t('common:disable') : t('common:enable'),
            rejectLabel: t('common:cancel'),
            breakpoints: {'960px': '75vw', '640px': '100vw'},
            accept: () => {
                 CustomerService.setExternalClientStatus(customer.externalId, e.value).then( () => {
                     setActiveCustomer(e.value);
                 }).catch( error => {
                     console.error(error);
                 });

            },
            reject: () => {
            }
        });
    }

    const confirmDeleteCustomer = () => {
        confirmDialog({
            message: t('common:confirmDeleteCustomer.message'),
            header: t('common:confirmDeleteCustomer.title'),
            icon: 'pi pi-times',
            acceptLabel: t('common:delete'),
            rejectLabel: t('common:cancel'),
            acceptClassName: 'p-button-danger',
            breakpoints: {'960px': '75vw', '640px': '100vw'},
            accept: () => {

            },
            reject: () => {
            }
        });
    }

    const amountCell = (rowData) => {
        // return (Number(rowData.amount.$numberDecimal)).toLocaleString('de-DE', {
        return (Number(rowData.amount)).toLocaleString('de-DE', {
            style: 'currency',
            currency: 'EUR',
        });
    }

    const referenceCell = (rowData) => {
        return (<strong>{rowData.ref}</strong>);
    }

    const dateCell = (rowData) => {
        return moment(rowData.createdAt).format('lll');
    }

    const statusCell = (rowData) => {
        switch (rowData.status) {
            case "pending":
                return <Tag severity="warning" value={t(`common:ORDER.STATUS.${rowData.status}`)}/>
            case "finalized":
                return <Tag severity="success" value={t(`common:ORDER.STATUS.${rowData.status}`)}/>
            case "cancelled":
                return <Tag severity="danger" value={t(`common:ORDER.STATUS.${rowData.status}`)}/>
            case "onCourse":
                return <Tag severity="info" value={t(`common:ORDER.STATUS.${rowData.status}`)}/>
            default:
                return <Tag severity="info" value={ t(`common:ORDER.STATUS.${rowData.status}`) }/>
        }
    }

    return (
        <BlockUI blocked={loading} fullScreen>
            <div className="grid-nogutter">
                <div className="col-12">
                    <div className="flex justify-content-between align-items-center mb-5 flex-wrap">
                        <div className="flex justify-content-end align-items-center">
                            <Button icon="pi pi-arrow-left"
                            onClick={() => history.goBack()}
                                    className="p-button-rounded p-button-secondary p-button-text mr-2"/>
                            <h2 className="m-0 p-0 sm:mb-2">{customer?.displayName}</h2>
                            <Tag className="ml-5" severity="info"
                                 value={customer?.type || t(`common:contactTypes.${customer?.type}`)}/>
                        </div>

                        <div className="flex justify-content-end align-items-center flex-wrap">
                            <Menu model={itemsMenu} popup ref={menu} id="popup_menu"/>
                            <Button icon="pi pi-ellipsis-v" className="p-button-outlined p-button-secondary"
                                    onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu"
                                    aria-haspopup/>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <TabView>
                        <TabPanel header={t('common:summary')}>
                            <div className="grid">
                                <div className="col-3">
                                    <div className="card">
                                        <div className="flex justify-content-between align-items-center">
                                            <span className="font-bold">{t('common:status')}</span>
                                            <InputSwitch checked={activeCustomer} onChange={setUserActive}/>
                                        </div>
                                    </div>
                                    <div className="card">
                                        {customer?.name == null || <div className="field">
                                            <span className="block">{t('common:name')}</span>
                                            <label
                                                className="block pt-2 font-bold text-green-500">{customer?.name}</label>
                                        </div>}
                                        {customer?.surname == null || <div className="field">
                                            <span className="block">{t('common:surname')}</span>
                                            <label
                                                className="block pt-2 font-bold text-green-500">{customer?.surname}</label>
                                        </div>}
                                        {customer?.code == null || <div className="field">
                                            <span className="block">{t('common:codeNum')}</span>
                                            <label
                                                className="block pt-2 font-bold text-green-500">{customer?.code}</label>
                                        </div>}
                                        {customer?.email == null || <div className="field">
                                            <span className="block">{t('common:email')}</span>
                                            <label
                                                className="block pt-2 font-bold text-green-500">{customer?.email}</label>
                                        </div>}
                                        {customer?.mobile == null || <div className="field">
                                            <span className="block">{t('common:mobile')}</span>
                                            <label
                                                className="block pt-2 font-bold text-green-500">{customer?.mobile?.prefix ?? ''} {customer?.mobile?.number ?? ''}</label>
                                        </div>}

                                        {customer?.tags == null || <div className="field">
                                            <span className="block">{t('common:tags')}</span>
                                            <div className="mt-2">
                                                {customer.tags.map((tag, i) => {
                                                    return (<Tag key={i} severity="info" value={'#' + tag}
                                                                 className="mr-2 mt-1 text"/>);
                                                })}
                                            </div>
                                        </div>}
                                    </div>
                                    <div className="card">
                                        <div className="flex justify-content-between align-items-center">
                                            <span className="font-bold">{t('common:otherInfo')}</span>
                                        </div>

                                        {customer?.dateOfBirth == null || <div className="field">
                                            <span className="block">{t('common:dateOfBirth')}</span>
                                            <label
                                                className="block pt-2 font-bold text-green-500">{customer?.dateOfBirth}</label>
                                        </div>}
                                        {customer?.gender == null || <div className="field">
                                            <span className="block">{t('common:gender')}</span>
                                            <label
                                                className="block pt-2 font-bold text-green-500">{customer?.gender}</label>
                                        </div>}

                                    </div>
                                </div>
                                <div className="col-9">
                                    <div className="grid">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="flex justify-content-between align-items-center">
                                                    <div className="mb-4">
                                                        <h4 className="p-0 m-0">{t('common:sales')}</h4>
                                                        <p className="text-black-alpha-80">
                                                            {(totalChartAmount).toLocaleString('de-DE', {
                                                                style: 'currency',
                                                                currency: 'EUR',
                                                            })}
                                                        </p>
                                                    </div>

                                                    <Dropdown value={selectedYearFilter} options={charSummaryOptions}
                                                              onChange={onChartFilterYearChange} optionLabel="name"
                                                              placeholder="Select a year"/>
                                                </div>
                                                <Chart type="line" data={chartData} options={basicOptions}
                                                       style={{maxWidth: '98%'}}/>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="card" style={{minHeight: "270px"}}>
                                                <div className="flex justify-content-between align-items-center">
                                                    <h4 className="p-0 m-0 font-bold">{t('common:shippingAddresses')}</h4>
                                                </div>
                                                {customer?.shippingAddress == null
                                                    ? <div className="mt-3 pb-6">{t('common:noAddresses')}</div>
                                                    : <div></div>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="card" style={{minHeight: "270px"}}>
                                                <div className="flex justify-content-between align-items-center">
                                                    <h4 className="p-0 m-0 font-bold">{t('common:billAddress')}</h4>
                                                    <Button icon="pi pi-pencil"
                                                            className="p-button-rounded p-button-success p-button-text"/>
                                                </div>
                                                {customer?.billAddress == null
                                                    ? <div className="mt-3 pb-6">{t('common:noAddresses')}</div>
                                                    : <div className="mt-3">
                                                        <div className="field">
                                                            <span className="block">{t('common:address')}</span>
                                                            <label
                                                                className="block pt-2 font-bold text-green-500">{customer?.billAddress?.address}</label>
                                                        </div>
                                                        <div className="flex flex-row">
                                                            <div className="field" style={{minWidth: "7rem"}}>
                                                                <span className="block">{t('common:postalCode')}</span>
                                                                <label
                                                                    className="block pt-2 font-bold text-green-500">{customer?.billAddress?.postalCode}</label>
                                                            </div>
                                                            <div className="field">
                                                                <span className="block">{t('common:city')}</span>
                                                                <label
                                                                    className="block pt-2 font-bold text-green-500">{customer?.billAddress?.city}</label>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-row">
                                                            <div className="field" style={{minWidth: "50%"}}>
                                                                <span className="block">{t('common:province')}</span>
                                                                <label
                                                                    className="block pt-2 font-bold text-green-500">{customer?.billAddress?.province}</label>
                                                            </div>
                                                            <div className="field">
                                                                <span className="block">{t('common:country')}</span>
                                                                <label
                                                                    className="block pt-2 font-bold text-green-500">{customer?.billAddress?.country}</label>
                                                            </div>
                                                        </div>

                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="card" style={{minHeight: "270px"}}>
                                                <div className="flex justify-content-between align-items-center">
                                                    <h4 className="p-0 m-0 font-bold">{t('common:otherInfo')}</h4>
                                                </div>
                                                <div className="mt-3">
                                                    <div className="field">
                                                        <span className="block">{t('common:createdAt')}</span>
                                                        <label
                                                            className="block pt-2 font-bold text-green-500">{customer?.createdAt}</label>
                                                    </div>
                                                    <div className="field">
                                                        <span className="block">{t('common:firstLogin')}</span>
                                                        <label
                                                            className="block pt-2 font-bold text-green-500">{customer?.createdAt}</label>
                                                    </div>
                                                    <div className="field">
                                                        <span className="block">{t('common:lastLogin')}</span>
                                                        <label
                                                            className="block pt-2 font-bold text-green-500">{customer?.createdAt}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header={t('common:orders')}>
                            <div className="grid">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="flex justify-content-between align-items-center mb-4">
                                            <h4 className="">{t('common:orderHistory')}</h4>
                                            <Dropdown value={selectedYearFilter} options={yearOptions}
                                                      onChange={onFilterYearChange} optionLabel="name"
                                                      placeholder="Select a year"/>
                                        </div>
                                        <DataTable value={orders} paginator rows={10}>
                                            <Column field="ref" style={{minWidth: '4rem'}}
                                                    header={t('common:reference')} sortable body={referenceCell}/>
                                            <Column field="createdAt" style={{minWidth: '4rem'}}
                                                    header={t('common:createdAt')} sortable body={dateCell}/>
                                            <Column field="company.name" style={{minWidth: '4rem'}}
                                                    header={t('common:company')} sortable/>
                                            <Column field="store.name" style={{minWidth: '4rem'}}
                                                    header={t('common:store')} sortable/>
                                            <Column field="amount" style={{minWidth: '4rem'}}
                                                    header={t('common:amount')} sortable body={amountCell}/>
                                            <Column field="status" style={{minWidth: '4rem'}}
                                                    header={t('common:status')} sortable body={statusCell}/>
                                            <Column body={actionBodyTemplate} exportable={false}
                                                    style={{minWidth: '4rem'}}/>
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        </BlockUI>
    );
}
export default CustomerDetails;
