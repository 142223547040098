import {initializeApp} from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

const firebaseConfig = {
    apiKey: "AIzaSyBc5I-SdJKmeiW1m-KgVu0jx1X-a7U_g6s",
    authDomain: "logistics-7d50f.firebaseapp.com",
    projectId: "logistics-7d50f",
    storageBucket: "logistics-7d50f.appspot.com",
    messagingSenderId: "959986832635",
    appId: "1:959986832635:web:bdfc4ee9bf6a80f0bacc5e"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firebaseAuth = getAuth(app);
//connectAuthEmulator(firebaseAuth, "http://localhost:9099");

const firebaseFunctions = getFunctions(app);
//connectFunctionsEmulator(firebaseFunctions, "localhost", 5001);

export const auth = firebaseAuth;
export const functions = firebaseFunctions;
