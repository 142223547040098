import React, {useState} from 'react';
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {useTranslation} from "react-i18next";
import {Avatar} from "primereact/avatar";
import {Tag} from "primereact/tag";

const UserList = () => {
    const {t} = useTranslation(["common"]);
    const [selectedFilterText, setSelectedFilterText] = useState('');
    const [filtering, setFiltering] = useState(false);

    const searchUsers = async () => {
        setFiltering(true);
        // await getUsers(null, null, null, null, null, selectedFilterText);
    }

    const resetSearch = async () => {
        setSelectedFilterText('');
        setFiltering(false);
        //await getCustomers(startFilterDate, endFilterDate, filterIsPerson, selectedFilterStatus, filterCompany);
    }

    return (
        <div className="grid-nogutter">
            <div className="col-12">
                <div className="flex justify-content-between align-items-center mb-5 flex-wrap">
                    <h2 className="m-0 sm:mb-2">{t('common:general.users')}</h2>
                    <div className="flex justify-content-end align-items-center">
                        <div className="p-inputgroup">
                            <InputText value={selectedFilterText}
                                       onChange={(e) => setSelectedFilterText(e.target.value)}
                                       placeholder={t('common:searchUser')}/>
                            {filtering === false
                                ? <Button disabled={selectedFilterText.length === 0} onClick={searchUsers}
                                          icon="pi pi-search"
                                          className="p-button-success"/>
                                : <Button onClick={resetSearch} icon="pi pi-times"
                                          className="p-button-danger"/>}
                        </div>
                        <Button label="Gestionar roles" icon="pi pi-lock"
                                className="p-button-outlined p-button-secondary ml-2 min-w-max"/>
                        <Button label={t('common:inviteUsers')} className="p-button-info ml-2 min-w-max"/>
                    </div>
                </div>
                <div className="card bg-white-alpha-50 shadow-none">
                    <div className="flex justify-content-between align-items-center mb-5 flex-wrap">
                        <div>
                            <h5 className="m-0 sm:mb-2">{t('common:general.users')}</h5>
                            <span>Gestiona el acceso a la plataforma de tu equipo</span>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-3">
                            <div className="card userItem">
                                <div className="flex flex-column justify-content-center align-items-center">
                                    <Avatar label="EM" className="bg-pink-500" size="large" shape="circle" />
                                    <h6 className="m-0 p-0 mt-4">Enrique Molina Márquez</h6>
                                    <small className="text-muted">enrique@creadia.com</small>
                                    <span className="text-900 text-green mt-2">HelloVan</span>
                                    <Tag className="mr-2 mt-3" value="Propietario"></Tag>
                                </div>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="card userItem">
                                <div className="flex flex-column justify-content-center align-items-center">
                                    <Avatar label="AO" className="bg-blue-200" size="large" shape="circle" />
                                    <h6 className="m-0 p-0 mt-4">Ana OrtegaGarcía</h6>
                                    <small className="text-muted">anaortega190@gmail.com</small>
                                    <span className="text-900 text-green mt-2">HelloVan</span>
                                    <Tag className="mr-2 mt-3" value="Admin"></Tag>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserList;
