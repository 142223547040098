import axios from 'axios';
import 'dotenv';

export default class CompanyService {

    static async getSmallListCompanies() {
        const apiUrl = process.env.REACT_APP_SERVER + 'companies/logistic/' + process.env.REACT_APP_LOGISTICID;
        return axios.get(apiUrl)
            .then((res) => {
                console.log(apiUrl);
                console.log(res.data);
                return res.data;
            }).catch((error) => {
                console.error(error.message);
            });
    }
}
